exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-wp-career-slug-js": () => import("./../../../src/pages/careers/{wpCareer.slug}.js" /* webpackChunkName: "component---src-pages-careers-wp-career-slug-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-factaday-js": () => import("./../../../src/pages/factaday.js" /* webpackChunkName: "component---src-pages-factaday-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-insights-wp-post-uri-js": () => import("./../../../src/pages/Insights/{wpPost.uri}.js" /* webpackChunkName: "component---src-pages-insights-wp-post-uri-js" */),
  "component---src-pages-team-member-wp-post-uri-js": () => import("./../../../src/pages/TeamMember/{wpPost.uri}.js" /* webpackChunkName: "component---src-pages-team-member-wp-post-uri-js" */),
  "component---src-pages-what-we-do-wp-what-we-do-uri-js": () => import("./../../../src/pages/whatWeDo/{wpWhatWeDo.uri}.js" /* webpackChunkName: "component---src-pages-what-we-do-wp-what-we-do-uri-js" */),
  "component---src-pages-whatwedo-js": () => import("./../../../src/pages/whatwedo.js" /* webpackChunkName: "component---src-pages-whatwedo-js" */)
}

